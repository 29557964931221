import firebase from "firebase";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/*const firebaseConfig = {
  apiKey: "AIzaSyDrA3EgGeKlF6uImb9_kcmleQqnn5KBu6k",
  authDomain: "tinder-clone-4a6cd.firebaseapp.com",
  projectId: "tinder-clone-4a6cd",
  storageBucket: "tinder-clone-4a6cd.appspot.com",
  messagingSenderId: "463522799804",
  appId: "1:463522799804:web:cf2b94d4a3f79ddb12f0b9",
  measurementId: "G-5XE80M3TMD"
};*/

const firebaseConfig = {
  apiKey: "AIzaSyDGO63lULyWPs1Ils1cw6Nxz_75Ozqfpx8",
  authDomain: "m1r1n0.firebaseapp.com",
  projectId: "m1r1n0",
  storageBucket: "m1r1n0.appspot.com",
  messagingSenderId: "190205870947",
  appId: "1:190205870947:web:a6386ace4ab825ba510956",
  measurementId: "G-73D92MELRV"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const database = firebaseApp.firestore();

export default database;
