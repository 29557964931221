import "./TinderCards.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import React, { useEffect, useMemo, useRef, useState } from "react";

import Button from "@material-ui/core/Button";
import { Carousel } from 'react-responsive-carousel';
import CloseIcon from "@material-ui/icons/Close";
import FavoriteIcon from "@material-ui/icons/Favorite";
import IconButton from "@material-ui/core/IconButton";
import PersonIcon from "@material-ui/icons/Person";
import ReplayIcon from "@material-ui/icons/Replay";
import StarRateIcon from "@material-ui/icons/StarRate";
import TinderCard from "react-tinder-card";
import database from "./firebase";
import { emojisplosion } from "emojisplosion";
import { useHistory } from "react-router-dom";

function TinderCards({ onSwipeButtonsClick, button }) {
  const [people, setPeople] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(3);
  const [lastDirection, setLastDirection] = useState();
  const history = useHistory();
  // used for outOfFrame closure
  const currentIndexRef = useRef(currentIndex);  

  useEffect(() => {
    const unsubscribe = database
      .collection("people")
      .onSnapshot((snapshot) =>
        setPeople(snapshot.docs.map((doc) => doc.data())),      
      );

    return () => {
      unsubscribe();
    };
  }, []);

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const childRefs = useMemo(
    () =>
      Array(people.length)
        .fill(0)
        .map((i) => React.createRef()),
    [people]
  );
  
  const canGoBack = currentIndex < 3;

  let canSwipe = currentIndex >= 0;

  // set last direction and decrease current index
  const swiped = (direction, nameToDelete, index) => {
    if (direction === "right") {
      emojisplosion({
        emojis: ["👍", "😍", "✅", "🤗", "🥂"],
        position: () => ({
          x: document.body.clientWidth / 2,
          y: document.body.clientHeight / 2,
        }),        
      });
    } else if (direction === "left") {
      emojisplosion({
        emojis: ["👎", "❌", "🙅‍♂️", "🙅‍♀️", "⛔"],
        position: () => ({
          x: document.body.clientWidth / 2,
          y: document.body.clientHeight / 2,
        }),         
      });    
    }

    setLastDirection(direction);
    updateCurrentIndex(index - 1);
    canSwipe = false;
  };

  const outOfFrame = (name, idx) => {
    console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current);
    // handle the case in which go back is pressed before card goes outOfFrame
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
    // TODO: when quickly swipe and restore multiple times the same card,
    // it happens multiple outOfFrame events are queued and the card disappear
    // during latest swipes. Only the last outOfFrame event should be considered valid

    if (name === "Feng") {
      console.log("IT WAS FENG!");
      history.push('/match')
    }      
  };

  const swipe = async (dir) => {
    if (canSwipe && currentIndex >= 0) {
      await childRefs[currentIndex].current.swipe(dir); // Swipe the card!
      if (people[currentIndex].name === "Feng") {
        history.push('/match')
      }      
    }
  };

  // increase current index and show card
  const goBack = async () => {
    if (!canGoBack) {
      return;
    }

    const newIndex = currentIndex + 1;
    updateCurrentIndex(newIndex);
    await childRefs[newIndex].current.restoreCard();
  };

  const profilePopup = async (name) => {
    //history.push('/match')
    history.push('/')
    
    if (name === "mike" || currentIndex === 3) {
      document.getElementById('profilePopup-mike').style.display = 'block';  
    } else {
      document.getElementById('profilePopup-' + people[currentIndex].name.toLowerCase()).style.display = 'block';
    }
  }

  const profileClose = async (name) => {
    document.getElementById('profilePopup-' + name).style.display = 'none';
  }

  switch (button) {
    case "goBack":
      goBack();
      break;
    case "left":
      swipe("left");
      break;
    case "right":
      swipe("right");
      break;
    default:
      break;
  }

  return (
    <div>
      <IconButton className="profileButton" onClick={() => profilePopup("mike")}>
        <PersonIcon className="header__icon" fontSize="large" />
      </IconButton>      
      <div className="profilePopup" id="profilePopup-mike">
        <IconButton
          className="profileClose"
          onClick={() => profileClose("mike")}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
        <Carousel
         showStatus={false}
         showThumbs={false}>
          <div>
              <img src="/pictures/mike/1.jpg" />
          </div>
          <div>
              <img src="/pictures/mike/2.jpg" />
          </div>
          <div>
              <img src="/pictures/mike/3.jpg" />
          </div>
          <div>
              <img src="/pictures/mike/4.jpg" />
          </div>
        </Carousel> 
        <div className="deets">
          <h2>Taylor, 35</h2>
          <p>Google</p>
          <p>City University London</p>
        </div>
        <div className="bio">
          <p>My name is actually Mike. Ignore Tinder.</p>
          <br />
          <p>Work in tech. Traveller, snowboarder, explorer.</p>
        </div>
      </div>

      <div className="profilePopup" id="profilePopup-feng">
        <IconButton
          className="profileClose"
          onClick={() => profileClose("feng")}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
        <Carousel
         showStatus={false}
         showThumbs={false}>
          <div>
              <img src="/pictures/feng/1.jpg" />
          </div>
          <div>
              <img src="/pictures/feng/2.jpg" />
          </div>
          <div>
              <img src="/pictures/feng/3.jpg" />
          </div>
          <div>
              <img src="/pictures/feng/4.jpg" />
          </div>
          <div>
              <img src="/pictures/feng/5.jpg" />
          </div>
          <div>
              <img src="/pictures/feng/6.jpg" />
          </div>                                                
        </Carousel> 
        <div className="deets">
          <h2>Feng, 26</h2>
          <p>University of Oxford</p>
          <p>0.5 meters away</p>
        </div>
        <div className="bio">
          <p>Animal: 🐯</p>
          <p>Fruit: 🍉</p>
          <p>Sport: ⛷️</p>
          <p>Guilty pleasures: 🍟🍔🍾🏍️🎉⛰️</p>
          <p>Languages: 🇬🇧🇫🇷🇨🇳</p>
          <br />
          <p>You turn to tell me something interesting about yourself...</p>
        </div>
      </div>
      <div className="profilePopup" id="profilePopup-colette">
        <IconButton
          className="profileClose"
          onClick={() => profileClose("colette")}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
        <Carousel
         showStatus={false}
         showThumbs={false}>
          <div>
            <img src="/pictures/colette/1.png" />
          </div>
          <div>
            <img src="/pictures/colette/2.png" />
          </div>
          <div>
            <img src="/pictures/colette/3.png" />
          </div>
          <div>
            <img src="/pictures/colette/4.png" />
          </div>
        </Carousel> 
        <div className="deets">
          <h2>Colette, (age confidential)</h2>
          <p>Springfield School, Hayes</p>
          <p>0.5 meters away</p>
        </div>
        <div className="bio">
          <p>Best Mum in the world.</p>
          <p>Enjoys trips to the Carribean.</p>
        </div>
      </div> 
      <div className="profilePopup" id="profilePopup-ian">
        <IconButton
          className="profileClose"
          onClick={() => profileClose("ian")}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
        <Carousel
         showStatus={false}
         showThumbs={false}>
          <div>
            <img src="/pictures/ian/1.png" />
          </div>
          <div>
            <img src="/pictures/ian/2.jpg" />
          </div>
          <div>
            <img src="/pictures/ian/3.jpg" />
          </div>
          <div>
            <img src="/pictures/ian/4.jpg" />
          </div>
        </Carousel> 
        <div className="deets">
          <h2>Ian, 35</h2>
          <p>Team GB</p>
          <p>4 meters away</p>
        </div>
        <div className="bio">
          <p>6th fastest man in the world.</p>
          <p>3 time Danger Golf World Cup winner.</p>
          <p>Former manager of Byfleet FC</p>
        </div>
      </div>            
      <div>
        <div className="tinderCards__cardContainer">
          {people.map((person, index) => (
            <TinderCard
              ref={childRefs[index]}
              className="swipe"
              key={person.name}
              preventSwipe={["up", "down"]}        
              onSwipeRequirementUnfulfilled={() => profilePopup(person.name)}     
              swipeRequirementType='position'
              onSwipe={(dir) => swiped(dir, person.name, index)}
              onCardLeftScreen={() => outOfFrame(person.name, index)}
            >
              <div 
                style={{ 
                  backgroundImage: `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 20%), url(${person.url})`  }}
                className="card"
              >
                <h3>{person.name}</h3>
              </div>
            </TinderCard>
          ))}
        </div>
      </div>
      <div className="buttonContainer">
        <Button variant="outlined" onClick={() => profilePopup("Feng")}>View Profile</Button>
      </div>
      <div className="swipeButtons">
        <IconButton
          className="swipeButtons__repeat"
          onClick={() => goBack("goBack")}
        >
          <ReplayIcon fontSize="large" />
        </IconButton>
        <IconButton
          className="swipeButtons__left"
          onClick={() => swipe("left")}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
        <IconButton
          className="swipeButtons__star"
          onClick={() => swipe("right")}
        >
          <StarRateIcon fontSize="large" />
        </IconButton>
        <IconButton
          className="swipeButtons__right"
          onClick={() => swipe("right")}
        >
          <FavoriteIcon fontSize="large" />
        </IconButton>
      </div>    
    </div>
  );
}

export default TinderCards;
