import "./App.css";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import ChatScreen from "./ChatScreen";
import Chats from "./Chats";
import Header from "./Header";
import Match from "./Match";
import React from "react";
import SwipeButtons from "./SwipeButtons";
import TinderCards from "./TinderCards";

function App() {
  const [swipeButtonClickedName, setSwipeButtonClickedName] = React.useState(
    null
  );

  const onSwipeButtonsClicked = React.useCallback((button) => {
    setSwipeButtonClickedName(button);    
  }, []);

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/chat/:person">
            <Header backButton="/chat" />
            <ChatScreen />
          </Route>
          <Route path="/chat">
            <Header backButton="/" />
            <Chats />
          </Route>
          <Route path="/match">
            <Header />
            <Match />            
          </Route>              
          <Route path="/">
            <Header />
            <TinderCards
              onSwipeButtonsClick={onSwipeButtonsClicked}
              button={swipeButtonClickedName}
            />          
              
          </Route>      
        </Switch>
      </Router>
    </div>
  );
}

//<SwipeButtons onSwipeButtonsClick={onSwipeButtonsClicked} />

export default App;
