import "./Match.css";

import { Link, useHistory } from "react-router-dom";
import { emojisplosion, emojisplosions } from "emojisplosion";

import React from "react";

const Match = ({ onMatchClick }) => {
  const { cancel } = emojisplosions({
    emojis: ["💖", "💕", "💗", "💓", "💝"],
  });

  setTimeout(cancel, 5000);

  return (
    <div className="match">
        <Link to="/chat">
        <div className="match-container">
            <img src="/match2.png" className="match-image" alt="It's a match!" />
        </div>
        </Link>
    </div>
  );
};

export default Match;
