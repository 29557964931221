import React from "react";
import "./Chats.css";
import Chat from "./Chat";

const Chats = () => {
  return (
    <div className="chats">
      <Chat
        name="Feng"
        message="Hmmm... Sure, why not!"
        timestamp="6 years ago"
        profilePic="./feng.png"
      />
      <Chat
        name="Mum"
        message="You look so handsome!"
        timestamp="5 seconds ago"
        profilePic="./mum.png"
      />
    </div>
  );
};

export default Chats;
