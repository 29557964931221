import "./ChatScreen.css";

import React, { useEffect, useRef, useState } from "react";

import Avatar from "@material-ui/core/Avatar";

const ChatScreen = () => {
  const messagesEndRef = useRef(null);
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [messagesStore, setMessagesStore] = useState([
    {
      message: "Hey Feng!"
    },
    {
      message: "Something interesting about me..."
    },
    {
      message: "Hope this gets the conversation started!"
    },
    {
      message:
        "I did a ski season in Whistler 5 years ago. While there I witnessed GB win a gold medal in the skeleton. While watching my friends and I were on the TV as those token crazy Brits in the cold with flags and face paint!"
    },
    {
      message:
        "Great picture of the Milky Way btw. Did you take that yourself?!"
    },
    {
      name: "Feng",
      image: "/feng.png",
      message:
        "That's pretty cool. I'm very jealous that you had a chance of spending a ski season in Whistler!"
    },
    {
      name: "Feng",
      image: "/feng.png",
      message: "Haha I asked a friend to help me, that shadow is me :)"
    },
    {
      name: "Feng",
      image: "/feng.png",
      message: "Why does Tinder call you Taylor instead of Mike?"
    },
    {
      message:
        "Haha that is a long story. It's actually linked to my days in Whistler."
    },
    {
      message: "How about I tell you over a drink?"
    },
    {
      name: "Feng",
      image: "/feng.png",
      message: "Forward - refreshing"
    },
    {
      name: "Feng",
      image: "/feng.png",
      message: "Hmmm... Sure, why not!"
    }
  ]);

  const [clickedOutside, setClickedOutside] = useState(false);
  const submitButton = useRef();

  const handleClickOutside = (e) => {
    if (!submitButton.current.contains(e.target)) {
      setClickedOutside(true);
    }

    loadMessage(e);
  };

  const handleClickInside = () => setClickedOutside(false);

  useEffect(() => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const loadMessage = (e) => {
    e.preventDefault();
    if (messages.length !== messagesStore.length) {
      setMessages([...messages, messagesStore[messages.length]]);
    }

    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleSend = (e) => {
    e.preventDefault();
    //setMessages([...messages, { message: input }]);
    setInput("");
  };
  return (
    <div className="chatScreen">
      <p className="chatScreen__timestamp">
        YOU SUPER LIKED FENG ON 30/11/2015
      </p>
      <div className="chatScreen__messages">
        {messages.map((message) =>
          message.name ? (
            <div className="chatScreen__message" key={message.message.replace(" ", "")}>
              <Avatar
                className="chatScreen__image"
                alt={message.name}
                src={message.image}
              />
              <p className="chatScreen__text">{message.message}</p>
            </div>
          ) : (
            <div className="chatScreen__message" key={message.message.replace(" ", "")}>
              <p className="chatScreen__owntext">{message.message}</p>
            </div>
          )
        )}
        <div ref={messagesEndRef} />
      </div>
      <form className="chatScreen__form">
        <input
          className="chatScreen__input"
          type="text"
          value={input}
          //onChange={(e) => setInput(e.target.value)}
        />
        <button
          onClick={handleSend}
          type="submit"
          className="chatScreen__button"
          ref={submitButton}
        >
          SEND
        </button>
      </form>
    </div>
  );
};

export default ChatScreen;
